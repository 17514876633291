<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.history") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table class="table-padding-2-no-top">
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              {{ $t("labels.spend") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.accrue_point") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.identity_change") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.time_change") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, index) in items"
            :key="`h_${index}`"
          >
            <td>{{ formatNumber(item.spend) }}</td>
            <td>{{ formatNumber(item.point) }}</td>
            <td>{{ item.identity_name }}</td>
            <td>{{ formatDateTime(item.created_at) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "AccruePointHistory",
  data: () => ({
    items: [],
  }),
  mounted() {
    this.getHistories();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getHistories() {
      httpClient.post("/customer-accrue-point-log").then(({ data }) => {
        this.items = [...data];
      });
    },
  },
};
</script>

<style scoped></style>
